import FullCalendar from '@fullcalendar/vue'
import CalendarEvent from '../components/CalendarEvent.vue'
import CalendarEventYear from '../components/CalendarEventYear.vue'
import CalendarEventList from '../components/CalendarEventList.vue'

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import multiMonthPlugin from '@fullcalendar/multimonth'
import listPlugin from '@fullcalendar/list'
import ruLocale from '@fullcalendar/core/locales/ru'

import axios from 'axios'
import { setData, getById } from '../utils/indexedDb.js'
import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'
import { calendarConfig } from '../utils/index.js'

export default {
    components: {
        FullCalendar,
        CalendarEvent,
        CalendarEventList,
        CalendarEventYear
    },
    props: {
        uKey: {
            type: [String, Number],
            default: 'default'
        },
        related_object: {
            type: [String, Number],
            default: null
        },
        addEventCheck: {
            type: Boolean,
            default: true
        },
        defaultType: {
            type: String,
            default: ''
        },
        page_name: {
            type: String,
            default: ''
        }
    },
    computed: {
        activeType() {
            if(this.defaultType.length)
                return this.defaultType
            else
                return this.$store.state.calendar.activeType
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        dayDrawerContainer() {
            if(this.related_object) {
                return () => document.body
            } else {
                return false
            }
        }
    },
    data() {
        return {
            events: [],
            showAside: true,
            todayCheck: true,
            calendarRequest: null,
            loading: false,
            configInit: true,
            configLoader: false,
            viewCache: '',
            relatedInfo: null,
            selectedDay: '',
            selectedDayRange: null,
            dayVisible: false,
            dayEvents: [],
            dyEventsEmpty: false,
            dayLoading: false,
            calendarOptions: {
                timeFormat: calendarConfig.timeFormat,
                events: [],
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    listPlugin,
                    multiMonthPlugin
                ],
                selectAllow: select => {
                    const start = select.start;
                    const end = select.end;
                    if (this.$moment(start).format('Hms') === '000' && this.$moment(end).format('Hms') === '000') {
                        return true;
                    }
                    return this.$moment(start).format('Y-MM-DD') === this.$moment(end).format('Y-MM-DD');
                },
                views: {
                    multiMonthFourMonth: {
                        type: 'multiMonth',
                        duration: { months: 4 }
                    },
                    day: {
                        dayHeaderFormat: { weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true }
                    }
                },
                nowIndicator: true,
                headerToolbar: false,
                dropAccept: '.drop-act',
                minTime: calendarConfig.minTime,
                handleWindowResize: true,
                expandRows: true,
                eventLimit: true,
                maxTime: calendarConfig.maxTime,
                aspectRatio: 1.5,
                slotLabelFormat: {hour: 'numeric', minute: '2-digit', hour12: false},
                locale: ruLocale,
                initialView: this.defaultType.length ? this.defaultType : localStorage.getItem('cType') || 'dayGridMonth',
                initialEvents: [],
                editable: true,
                selectable: true,
                resizable: true,
                droppable: true,
                selectMirror: true,
                height: '100%',
                dayMaxEvents: true,
                weekends: true,
                datesSet: this.datesSet,
                select: this.handleDateSelect,
                dateClick: this.dateClick,
                eventClick: this.handleEventClick,
                eventsSet: this.handleEvents,
                eventChange: this.eventChange,
                eventDrop: this.eventDrop,
                titleFormat: {
                    weekday: 'long'
                },
                eventResize: this.eventResize
            }
        }
    },
    created() {
        this.getConfig()
    },
    methods: {
        floatAddEvent() {
            eventBus.$emit('open_event_form', 
                null, 
                null, 
                null, 
                this.relatedInfo, 
                this.uKey)
        },
        afterVisibleChange(vis) {
            if(!vis) {
                this.selectedDay = ''
                this.selectedDayRange = null
                this.dayEvents = []
                this.dyEventsEmpty = false
            }
        },
        checkDayVisible() {
            if(this.dayVisible)
                this.dayVisible = false
        },
        changeAsideShow() {
            this.showAside = !this.showAside
            setTimeout(() => {
                this.$refs.fullCalendar.getApi().updateSize()
            }, 100)
        },
        async getCalendarId() {
            try {
                const { data } = await this.$http.get(`calendars/related/${this.related_object}/`)
                if(data) {
                    this.relatedInfo = data
                }
            } catch(e) {
                console.log(e)
            }
        },
        async getConfig() {
            try {
                this.configLoader = true
                await new Promise((resolve, reject) => {
                    getById({ id: 'calendar', databaseName: 'config' })
                        .then(async dbData => {
                            if (dbData?.value) {
                                this.calendarOptions.timeFormat = dbData?.value?.timeFormat || calendarConfig.timeFormat
                                this.calendarOptions.minTime = dbData?.value?.minTime || calendarConfig.minTime
                                this.calendarOptions.maxTime = dbData?.value?.maxTime || calendarConfig.maxTime

                                if(this.isMobile) {
                                    this.calendarOptions.height = 'auto'
                                    this.calendarOptions.initialView = localStorage.getItem('cType') || 'timeGridDay'
                                }

                                if(this.related_object)
                                    await this.getCalendarId()

                                resolve(dbData)
                            } else {
                                this.$http.get('/calendars/info/')
                                    .then(({data}) => {
                                        setData({
                                            data: {
                                                id: 'calendar',
                                                value: data
                                            },
                                            databaseName: 'config'
                                        })
                                            .then(async () => {
                                                this.calendarOptions.timeFormat = data?.timeFormat || calendarConfig.timeFormat
                                                this.calendarOptions.minTime = data?.minTime || calendarConfig.minTime
                                                this.calendarOptions.maxTime = data?.maxTime || calendarConfig.maxTime

                                                if(this.isMobile) {
                                                    this.calendarOptions.height = 'auto'
                                                    this.calendarOptions.initialView = localStorage.getItem('cType') || 'timeGridDay'
                                                }

                                                if(this.related_object)
                                                    await this.getCalendarId()

                                                resolve(data)
                                            })
                                            .catch(e => {
                                                reject(e)
                                            })
                                    })
                                    .catch(e => {
                                        reject(e)
                                    })
                            }
                        })
                        .catch(e => {
                            console.log(e)
                        })
                })
            } catch(e) {
                console.log(e)
            } finally {
                this.configInit = false
                this.configLoader = false
            }
        },
        selectOneDay(date) {
            this.$refs.fullCalendar.getApi().gotoDate(date.toISOString())
            this.$store.commit('calendar/SET_ACTIVE_TYPE', 'timeGridDay')
            this.$refs.fullCalendar.getApi().changeView('timeGridDay')
        },
        async eventResize(event) {
            const key = `update_${event.event.id}`
            try {
                this.$message.loading({ content: 'Обновление события', key })
                await this.$store.dispatch('calendar/changeEventDate', event)
            } catch(e) {
                console.log(e)
                this.$message.error({ content: 'Ошибка', key })
                event.revert()
            } finally {
                this.$message.success({ content: 'Событие обновлено', key })
            }
        },
        datesSet(e) {
            const endStr = e.endStr,
                startStr = e.startStr
            if(this.$moment().isBetween(startStr, endStr)) {
                this.todayCheck = true
            } else {
                this.todayCheck = false
            }
            if(this.viewCache !== e.view.type) {
                this.viewCache = e.view.type
                this.getEventsType()
            }
        },
        async eventDrop(event) {
            const key = `update_${event.event.id}`
            try {
                this.$message.loading({ content: 'Обновление события', key })
                await this.$store.dispatch('calendar/changeEventDate', event)
                this.$message.success({ content: 'Событие обновлено', key })
            } catch(error) {
                console.log(error)
                if(error.detail === 'У вас недостаточно прав для выполнения данного действия.') {
                    this.$message.error({ content: 'Вы не являетесь организатором события и не можете его перемещать', key })
                } else {
                    this.$message.error({ content: 'Ошибка', key })
                }
                event.revert()
            }
        },
        eventChange(event) {
            // console.log(event, 'eventChange')
        },
        clearEvents() {
            this.events = []
            this.calendarOptions.events = []
        },
        async getEventsType(clear = true) {
            if(this.activeType !== 'multiMonthYear') {
                await this.getEvents(clear)
            } else {
                await this.getYearEvents()
            }
        },
        async getYearEvents() {
            try {
                this.loading = true
                const {start, end} = this.$refs.fullCalendar.getApi().currentData.dateProfile.activeRange,
                    startDate = this.$moment(start).add(-1, 'days').toISOString(),
                    endDate = this.$moment(end).toISOString()
                
                const params = {
                    start: startDate,
                    end: endDate,
                    ranges: true
                }

                if(this.related_object) {
                    params.related_object = this.related_object
                }

                const { data } = await this.$http.get('/calendars/events/', {
                    params
                })
                if(data) {
                    const eArray = data.map((item, index) => {
                        return {
                            ...item,
                            id: index,
                            start: this.$moment(item.start_at).format('YYYY-MM-DD'),
                            end: this.$moment(item.end_at).format('YYYY-MM-DD'),
                            diff: this.$moment(this.$moment(item.end_at).format('YYYY-MM-DD')).diff(this.$moment(item.start_at).format('YYYY-MM-DD'), 'days'),
                            color: '#000000',
                            editable: false,
                            allDay: true,
                            selectable: false,
                            resizable: false,
                            droppable: false
                        }
                    })

                    eArray.forEach((item, index) => {
                        const diff = this.$moment(item.end).diff(item.start, 'days')
                        if(diff > 1) {
                            let start = item.start,
                                end = item.end
                            for (let i = 0; i < diff; i++) {
                                eArray.push({
                                    ...item,
                                    id: `s_${i}_${index}`,
                                    start: start,
                                    end: this.$moment(start).format('YYYY-MM-DD'),
                                    color: '#000000',
                                    editable: false,
                                    allDay: true,
                                    added: true,
                                    selectable: false,
                                    resizable: false,
                                    droppable: false
                                })

                                start = this.$moment(start).add(1, 'days').format('YYYY-MM-DD')
                                end = this.$moment(start).add(1, 'days').format('YYYY-MM-DD')
                            }
                        }
                    })

                    eArray.forEach(item => {
                        const index = eArray.findIndex(f => this.$moment(f.start).isSame(item.start , 'day') && !f.added && f.id !== item.id)
                        if(index !== -1) {
                            eArray.splice(index, 1)
                        }
                    })

                    this.calendarOptions.events = eArray
                    this.events = eArray
                } else {
                    this.calendarOptions.events = []
                    this.events = []
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        async getEvents(clear = true) {
            try {
                this.loading = true
                if(this.calendarRequest) {
                    this.calendarRequest.cancel()
                }

                const axiosSource = axios.CancelToken.source()
                this.calendarRequest = { cancel: axiosSource.cancel }

                const {start, end} = this.$refs.fullCalendar.getApi().currentData.dateProfile.activeRange,
                    startDate = this.$moment(start).add(-1, 'days').toISOString(),
                    endDate = this.$moment(end).toISOString()
                
                const params = {
                    start: startDate,
                    end: endDate
                }

                if(this.page_name)
                    params.page_name = this.page_name

                if(this.related_object) {
                    params.related_object = this.related_object
                }

                if(clear)
                    this.clearEvents()

                const { data } = await this.$http.get('/calendars/events/', {
                    cancelToken: axiosSource.token,
                    params
                })
                if(data) {
                    this.calendarOptions.events = this.eventReplace(data)
                    this.events = this.eventReplace(data)
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        addCalendar() {
            this.$refs['aside'].addCalendarHandler()
        },
        changeDate(date) {
            this.$refs.fullCalendar.getApi().gotoDate(date.toISOString())
            this.getEventsType()
            this.checkDayVisible()
        },
        handleChangeType(event) {
            const value = event.target.value
            this.$store.commit('calendar/SET_ACTIVE_TYPE', value)
            this.$refs.fullCalendar.getApi().changeView(value)
            this.checkDayVisible()
        },
        today(){
            if(!this.isMobile)
                this.$refs.aside.calendarDefault()

            this.$refs.fullCalendar.getApi().today()
            this.getEventsType()
            this.checkDayVisible()
        },
        antCalendarHeader() {
            return (
                <div></div>
            )
        },
        prev() {
            this.$refs.fullCalendar.getApi().prev()
            if(!this.isMobile) {
                this.$refs.aside.calendarDefault(this.$refs.fullCalendar.getApi().currentData.currentDate)
            }
            this.getEventsType()
            this.checkDayVisible()
        },
        next() {
            this.$refs.fullCalendar.getApi().next()
            if(!this.isMobile) {
                this.$refs.aside.calendarDefault(this.$refs.fullCalendar.getApi().currentData.currentDate)
            }
            this.getEventsType()
            this.checkDayVisible()
        },
        changeView(name) {
            this.activeType = name
            this.$refs.fullCalendar.getApi().changeView(name)
            this.checkDayVisible()
        },
        dateClick(selectInfo) {
            if(this.activeType === 'multiMonthYear' && this.isMobile) {
                this.selectedDayRange = {
                    start: this.$moment(selectInfo.dateStr).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(true),
                    end: this.$moment(selectInfo.dateStr).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 59).toISOString(true)
                }

                this.selectedDay = `События за ${this.$moment(selectInfo.dateStr).format('DD.MM.YYYY')}`
                if(!this.dayVisible)
                    this.dayVisible = true
                
                this.getDayEvents()
            }
        },
        handleDateSelect(selectInfo) {
            if(this.activeType === 'multiMonthYear') {
                this.selectedDayRange = {
                    start: selectInfo.start,
                    end: selectInfo.end
                }
                this.selectedDay = `События за ${this.$moment(selectInfo.startStr).format('DD.MM.YYYY')}`
                if(!this.dayVisible)
                    this.dayVisible = true
                
                this.getDayEvents()
            } else {
                if(this.addEventCheck) {
                    eventBus.$emit('open_event_form', 
                        selectInfo.startStr, 
                        selectInfo.endStr, 
                        null, 
                        this.relatedInfo,
                        this.uKey)
                }
            }
        },
        async getDayEvents() {
            try {
                this.dayLoading = true
                this.dyEventsEmpty = false
                this.dayEvents = []
                const startDate = this.$moment(this.selectedDayRange.start).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(true),
                    endDate = this.$moment(this.selectedDayRange.start).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 59).toISOString(true)

                const params = {
                    start: startDate,
                    end: endDate
                }
                if(this.related_object) {
                    params.related_object = this.related_object
                }

                const { data } = await this.$http.get('/calendars/events/', {params})
                if(data?.length) {
                    this.dayEvents = this.eventReplace(data)
                } else {
                    this.dyEventsEmpty = true
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.dayLoading = false
            }
        },
        handleEventClick(clickInfo) {
            if(this.activeType === 'multiMonthYear') {
                const { event } = clickInfo
                const start = event.startStr

                this.selectedDayRange = {
                    start: this.$moment(start).set('hour', 0).set('minute', 0).set('second', 0),
                    end: this.$moment(start).set('hour', 23).set('minute', 59).set('second', 59)
                }

                this.selectedDay = `События за ${this.$moment(start).format('DD.MM.YYYY')}`
                if(!this.dayVisible)
                    this.dayVisible = true
                
                this.getDayEvents()
            } else {
                const { id } = clickInfo.event
                let query = Object.assign({}, this.$route.query)
                if(query.event && Number(query.event) !== id || !query.event) {
                    query.event = id
                    this.$router.push({query})
                }
            }
        },
        handleEvents(events) {
            // console.log('change event')
            // console.log(events)
        },
        eventReplace(events) {
            return events.map(item => {
                return {
                    ...item,
                    title: item.name,
                    start: item.start_at,
                    end: item.end_at,
                    allDay: item.all_day,
                    extendedProps: {
                        ...item
                    }
                }
            })
        }
    },
    mounted() {
        eventBus.$on('delete_event', id => {
            const event = this.$refs.fullCalendar.getApi().getEventById(id)
            if(event) {
                event.remove()
            }
        })
        eventBus.$on('edit_event', value => {
            if(this.events.length) {
                const index = this.events.findIndex(f => f.id === value.id)
                if(index !== -1) {
                    const event = this.$refs.fullCalendar.getApi().getEventById(value.id)
                    if(event) {
                        event.remove()
                    }
                    this.$set(this.events, index, value)
                    this.calendarOptions.events = this.eventReplace(this.events)
                }
            }
            if(this.dayEvents.length) {
                const index = this.dayEvents.findIndex(f => f.id === value.id)
                if(index !== -1) {
                    const rData = this.eventReplace([value])
                    this.$set(this.dayEvents, index, rData[0])
                }
            }
        })
        eventBus.$on(`add_event_${this.uKey}`, value => {
            this.$refs.fullCalendar.getApi().addEvent({
                ...value,
                allDay: value.all_day,
                title: value.name,
                start: value.start_at,
                end: value.end_at,
                extendedProps: {
                    ...value
                }
            })
            this.events.push({
                ...value,
                allDay: value.all_day,
                title: value.name,
                start: value.start_at,
                end: value.end_at,
                extendedProps: {
                    ...value
                }
            })
        })
    },
    beforeDestroy() {
        eventBus.$off(`add_event_${this.uKey}`)
        eventBus.$off('edit_event')
        eventBus.$off('delete_event')
    }
}